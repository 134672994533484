@font-face {
  font-size: 16px;
  font-family: "Metropolis";
  src: local("Metropolis"),
    url("./shared/fonts/Metropolis-Regular.otf") format("opentype");
}

html,
body {
  margin: 0;
  font-family: Metropolis, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
}

html,
body,
div {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.root-content {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.content {
  padding: 32px;
  padding-top: 64px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
